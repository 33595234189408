import { memo } from 'react';
import PropTypes from 'prop-types';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

const MfaField = (props) => (
    <FormControlLabel
        label="Use multi-factor authentication"
        control={<Checkbox name="mfa" checked={props.checked} disabled={props.disabled} onChange={props.onChange} />}
    />
);

MfaField.propTypes = {
    checked: PropTypes.bool.isRequired,
    disabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
};

export default memo(MfaField);
