import { sendGetRequest, processResponse } from './dataRequestUtils';

const ignoreErrorCodes = [404];

/**
 * Get all apps
 * @param {Object} args
 * @returns {Promise<any>}
 */
export const getAllApps = ({ successCallback, errorCallback, finallyCallback } = {}) =>
    sendGetRequest({
        url: '/apps?includePublicApps=true',
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            ignoreErrorCodes,
            errorMessage: 'Unable to fetch apps from server',
        }),
    });

/**
 * Get app by ID
 * @param {Object} args
 * @return {Promise<any>} response
 */
export const getAppById = ({ appId, successCallback, errorCallback, finallyCallback } = {}) =>
    sendGetRequest({
        url: `/app/${appId}`,
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            errorMessage: 'Unable to fetch selected app from server',
        }),
    });

/**
 * Get app interface resources by app ID
 * @param {Object} args
 * @return {Promise<any>} response
 */
export const getAppInterfaceResourcesByInterfaceId = ({
    appId,
    interfaceId,
    successCallback,
    errorCallback,
    finallyCallback,
} = {}) =>
    sendGetRequest({
        url: `/app/${appId}/interface/${interfaceId}/resources`,
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            errorMessage: 'Unable to fetch selected interface resources from server',
            ignoreErrorCodes,
        }),
    });

/**
 * Get app interface resource actions by ID
 * @param {Object} args
 * @return {Promise<any>} response
 */
export const getAppInterfaceResourceActionsById = ({
    appId,
    interfaceId,
    resourceId,
    successCallback,
    errorCallback,
    finallyCallback,
} = {}) =>
    sendGetRequest({
        url: `/app/${appId}/interface/${interfaceId}/resource/${resourceId}/actions`,
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            ignoreErrorCodes,
            errorMessage: 'Unable to fetch selected resource actions from server',
        }),
    });
