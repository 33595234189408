import { getStoreState, setStoreState, useStoreValue } from './useStoreStateHook';

const storeNamespace = 'organizationLogging';

export const organizationLoggingStates = {
    selectedLogData: {
        data: [],
        loading: true,
        isInitRequestDone: false,
    },
    selectedLogNameItem: {
        name: 'api',
        title: 'API Calls',
        header: 'API Call',
    },
    isFilterLogVisible: false,
    logFilterData: {
        logName: '',
        logStatus: '',
        endTime: null,
        endDate: null,
        startTime: null,
        startDate: null,

        // API Call Filters
        route: '',
        ipAddress: '',

        // App Request Filters
        appInstanceId: '',
        appInterfaceId: '',
        appResourceId: '',
        appActionId: '',
    },
    areFiltersApplied: false,
    viewLogRequest: {
        open: false,
        title: '',
        content: '',
    },
    apps: {
        instances: [],
        resources: [],
        actions: [],
    },
};

/**
 * Get the selected log name and subscribe to changes
 * @returns {{ name: string, title: string }}
 */
export const useSelectedLogNameItem = () => useStoreValue('selectedLogNameItem', storeNamespace)();

/**
 * Set the selected log name
 * @param {{ name: String, title: String }} logNameItem
 */
export const setSelectedLogNameItem = (logNameItem) => setStoreState('selectedLogNameItem')(logNameItem);

/**
 * Get the selected log name
 * @returns {{ name: String, title: String }}
 */
export const getSelectedLogNameItem = () => getStoreState('selectedLogNameItem', storeNamespace)();

/**
 * Get filter log visibility state and subscribe to changes
 * @returns {boolean}
 */
export const useFilterLogVisibility = () => useStoreValue('isFilterLogVisible', storeNamespace)();

/**
 * Set filter log state
 * @param {boolean} isVisible
 */
export const setFilterLogVisibility = (isVisible) => setStoreState('isFilterLogVisible')(isVisible);

/**
 * Get log filter data state and subscribe to changes
 * @returns {{ logName: '', logStatus: '', startDate: dayjs|null, endDate: dayjs|null, searchText: string }}
 */
export const useLogFilterData = () => useStoreValue('logFilterData', storeNamespace)();

/**
 * Set filter log state
 * @param {{ logName: '', logStatus: '', startTime: string|null, endTime: string|null, startDate: string|null, endDate: string|null, searchText: string }} filterData
 */
export const setLogFilterData = (filterData) => setStoreState('logFilterData')(filterData);

/**
 * Get filter log state
 * @returns {{ logName: '', logStatus: '', startTime: string|null, endTime: string|null, startDate: string|null, endDate: string|null, searchText: string }} filterData
 */
export const getLogFilterData = () => getStoreState('logFilterData', storeNamespace)();

/**
 * Reset filter log state
 */
export const resetLogFilterData = () => {
    setStoreState('logFilterData')({
        logName: '',
        logStatus: '',
        endTime: null,
        endDate: null,
        startTime: null,
        startDate: null,
        searchText: '',

        // API Call Filters
        route: '',
        ipAddress: '',

        // App Request Filters
        appInstanceId: '',
        appInterfaceId: '',
        appResourceId: '',
        appActionId: '',
    });

    setStoreState('areFiltersApplied')(false);
};

/**
 * Get view log request state and subscribe to changes
 * @returns {{ open: boolean, content: string, title: string }}
 */
export const useViewLogRequest = () => useStoreValue('viewLogRequest', storeNamespace)();

/**
 * Set view log request state
 * @param {{ open: boolean, content: string, title: string }} data
 */
export const setViewLogRequest = (data) => setStoreState('viewLogRequest')(data);

/**
 * Get selected log state and subscribe to changes
 * @returns {{ data: [], loading: bool, isInitRequestDone: boolean, ...{} }}
 */
export const useSelectedLog = () => useStoreValue('selectedLogData', storeNamespace)();

/**
 * Set selected log state
 * @param {{ data: [], loading: boolean, isInitRequestDone: boolean, ...{} }} data
 */
export const setSelectedLog = (data) => setStoreState('selectedLogData')(data);

/**
 * Get selected log state
 * @returns {{ data: [], loading: boolean, isInitRequestDone: boolean, ...{} }} data
 */
export const getSelectedLog = () => getStoreState('selectedLogData', storeNamespace)();

/**
 * Set app instances list
 * @param {Array} instances
 */
export const setAppInstances = (instances) => {
    const apps = getStoreState('apps', storeNamespace)();
    apps.instances = instances;
    setStoreState('apps')({ ...apps });
};

/**
 * Set app resources list
 * @param {Array} resources
 */
export const setAppResources = (resources) => {
    const apps = getStoreState('apps', storeNamespace)();
    apps.resources = resources;
    setStoreState('apps')({ ...apps });
};

/**
 * Set app actions list
 * @param {Array} actions
 */
export const setAppActions = (actions) => {
    const apps = getStoreState('apps', storeNamespace)();
    apps.actions = actions;
    setStoreState('apps')({ ...apps });
};

/**
 * Use apps list and subscribe to changes
 * @returns {{ instances: [], resources: [], actions: [] }}
 */
export const useApps = () => useStoreValue('apps', storeNamespace)({});

/**
 * Get filtersAreApplied state and subscribe to changes
 * @returns {boolean}
 */
export const useAreFiltersApplied = () => useStoreValue('areFiltersApplied', storeNamespace)(false);

/**
 * Set filtersAreApplied state
 * @param {boolean} areFiltersApplied
 */
export const setAreFiltersApplied = (areFiltersApplied) => setStoreState('areFiltersApplied')(areFiltersApplied);
