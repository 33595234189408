import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import Cross from 'assets/icons/Cross';

const RemoveUserProfileImageButton = (props) => {
    const { userId = null, disabled = false, isSubmittingForm = false } = props;

    const removeIconStyle = {
        padding: 5,
        borderRadius: '50%',
        backgroundColor: '#EB5757',
        width: 23,
        height: 23,
        borderWidth: 1,
    };

    /**
     * Remove uploaded user profile image.
     */
    const handleRemoveUserProfileImage = () => {
        if (userId) {
            // TODO: remove image from server
        }
    };

    return (
        <IconButton
            color="primary"
            variant="contained"
            component="label"
            disabled={disabled || isSubmittingForm}
            onClick={handleRemoveUserProfileImage}
        >
            <Cross style={removeIconStyle} />
        </IconButton>
    );
};

RemoveUserProfileImageButton.propTypes = {
    userId: PropTypes.string,
    disabled: PropTypes.bool,
    isSubmittingForm: PropTypes.bool,
};

export default RemoveUserProfileImageButton;
