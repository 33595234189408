import { useState, useEffect, memo } from 'react';
import { updateUserDetails } from '../../../utils/userProfileUpdateUtils';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import Box from '@mui/material/Box';
import {
    showSnackBarErrorNotification,
    showSnackBarSuccessNotification,
} from '../../../utils/snackBarNotificationUtils';
import HttpStatusCodes from '../../../classes/HttpStatusCodes';

const NotificationsSettings = ({ userData }) => {
    const [notifications, setNotifications] = useState(userData?.notifications || []);
    const [isUpdatingNotificationSettings, setIsUpdatingNotificationSettings] = useState(false);

    const handleToggleNotifications = async (e) => {
        const notificationFlag = e.target.value;
        const isSetting = e.target.checked;
        let clonedNotifications = [...notifications];

        if (isSetting && !clonedNotifications.includes(notificationFlag)) {
            clonedNotifications.push(notificationFlag);
        } else if (!isSetting && clonedNotifications.includes(notificationFlag)) {
            clonedNotifications = clonedNotifications.filter((item) => item !== notificationFlag);
        }

        setIsUpdatingNotificationSettings(true);
        updateUserDetails({
            ...userData,
            notifications: clonedNotifications,
        })
            .then((res) => {
                showSnackBarSuccessNotification('Successfully updated notification settings');

                if (res.status === HttpStatusCodes.SUCCESS) {
                    setNotifications(res.data?.notifications || []);
                }
            })
            .catch(() => {
                showSnackBarErrorNotification('There was an error updating the notifications');
            })
            .finally(() => {
                setIsUpdatingNotificationSettings(false);
            });
    };

    useEffect(() => {
        if (userData?.notifications) {
            setNotifications(userData.notifications);
        }
    }, [userData?.notifications]);

    return (
        <div>
            <Typography sx={{ fontSize: 24 }}>Notification Settings</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
                <Switch
                    disabled={isUpdatingNotificationSettings}
                    checked={notifications.includes('build_notification')}
                    value={'build_notification'}
                    onChange={handleToggleNotifications}
                />
                <Typography>I want to be informed of system changes and new releases</Typography>
            </Box>
        </div>
    );
};

NotificationsSettings.propTypes = {
    userData: PropTypes.object.isRequired,
};

export default memo(NotificationsSettings);
