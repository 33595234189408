import Typography from '@mui/material/Typography';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';

import SearchIcon from 'assets/icons/Search';
import TextField from 'components/InputFields/TextField';
import SelectField from 'components/InputFields/SelectField';
import {
    setLogFilterData,
    useFilterLogVisibility,
    useLogFilterData,
    useSelectedLogNameItem,
    useApps,
    setAreFiltersApplied,
} from 'hooks/useOrganizationLoggingHook';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import { fetchLogs, fetchAppResources, fetchAppActions } from 'utils/loggingUtils';

const LogFilterContent = () => {
    const selectedLogType = useSelectedLogNameItem(),
        isVisible = useFilterLogVisibility(),
        logFilterData = useLogFilterData(),
        appData = useApps(),
        timeViews = ['hours', 'minutes'],
        datePickerSlotProps = {
            actionBar: {
                actions: ['clear'],
            },
        },
        isIntegrationLog = selectedLogType.name === 'integrations';

    const integrationStatuses = [
        { label: 'Success', value: 'Success' },
        { label: 'Errored', value: 'Errored' },
    ];

    const integrationWorkflowFieldInputProps = {
        InputProps: {
            endAdornment: <SearchIcon />,
        },
    };

    const workflowNameInputStyle = {
        '& .MuiOutlinedInput-root': {
            '& svg': {
                marginLeft: '10px',
                fontSize: '14px',
                cursor: 'pointer',
            },
        },
    };

    const searchFieldProps = {
            InputProps: {
                endAdornment: <SearchIcon />,
            },
        },
        searchInputStyle = {
            height: '35px',
            marginTop: '0px',

            '& .MuiOutlinedInput-root': {
                height: '33px',
                minWidth: '230px',
                borderRadius: '4px',

                '& input': {
                    borderRadius: '0px',
                },
            },

            '& svg': {
                fontSize: '14px',
                cursor: 'pointer',
            },
        };

    const handleDateChange = (state) => (date) => {
        setLogFilterData({
            ...logFilterData,
            [state]: date ?? null,
        });
    };

    const handleTimeChange = (state) => (time) => {
        setLogFilterData({
            ...logFilterData,
            [state]: time ?? null,
        });
    };

    const handleFieldChange = (e) => {
        const logFilterDataCopy = { ...logFilterData };
        const field = e.target.name;

        switch (field) {
            case 'appInstanceId':
                logFilterDataCopy.appInterfaceId = '';
                logFilterDataCopy.appResourceId = '';
                logFilterDataCopy.appActionId = '';
                break;
            case 'appInterfaceId':
                logFilterDataCopy.appResourceId = '';
                logFilterDataCopy.appActionId = '';

                // Populate the resource dropdown
                fetchAppResources({
                    appId: logFilterDataCopy.appInstanceId,
                    interfaceId: e.target.value,
                });
                break;
            case 'appResourceId':
                logFilterDataCopy.appActionId = '';

                // Populate the action dropdown
                fetchAppActions({
                    appId: logFilterDataCopy.appInstanceId,
                    interfaceId: logFilterDataCopy.appInterfaceId,
                    resourceId: e.target.value,
                });
                break;
            default:
                break;
        }

        setLogFilterData({
            ...logFilterDataCopy,
            [e.target.name]: e.target.value,
        });
    };

    const handleApplyFilters = async () => {
        fetchLogs({
            filters: logFilterData,
            searchText: logFilterData.searchText || '',
        });

        setAreFiltersApplied(true);
    };

    const renderAdditionalFilters = () => {
        switch (selectedLogType.name) {
            case 'api':
                return (
                    <>
                        <div className="filterLogContentField">
                            <Typography className="filterLogContentFieldLabel">Route</Typography>
                            <TextField
                                name="route"
                                sx={searchInputStyle}
                                value={logFilterData.route}
                                placeholder="Route"
                                renderWithGrid={false}
                                label={null}
                                required={false}
                                inputProps={searchFieldProps}
                                showError={false}
                                onChange={handleFieldChange}
                            />
                        </div>
                        <div className="filterLogContentField">
                            <Typography className="filterLogContentFieldLabel">IP Address</Typography>
                            <TextField
                                name="ipAddress"
                                sx={searchInputStyle}
                                value={logFilterData.ipAddress}
                                placeholder="IP Address"
                                renderWithGrid={false}
                                label={null}
                                required={false}
                                inputProps={searchFieldProps}
                                showError={false}
                                onChange={handleFieldChange}
                            />
                        </div>
                    </>
                );
            case 'app-request':
                return (
                    <>
                        <div className="filterLogContentField">
                            <Typography className="filterLogContentFieldLabel">App Instance</Typography>
                            <SelectField
                                menuWidth={60}
                                name="appInstanceId"
                                label={null}
                                value={logFilterData.appInstanceId}
                                placeholder="Select App Instance"
                                renderWithGrid={false}
                                required={false}
                                items={appData.instances.map((instance) => ({
                                    label: instance.name,
                                    value: instance._id,
                                }))}
                                onChange={handleFieldChange}
                            />
                        </div>
                        <div className="filterLogContentField">
                            <Typography className="filterLogContentFieldLabel">Interface</Typography>
                            <SelectField
                                menuWidth={60}
                                name="appInterfaceId"
                                label={null}
                                value={logFilterData.appInterfaceId}
                                placeholder="Select App Instance"
                                renderWithGrid={false}
                                required={false}
                                items={
                                    logFilterData.appInstanceId.length
                                        ? appData.instances
                                              .find((instance) => instance._id === logFilterData.appInstanceId)
                                              .interfaces.map((appInterface) => ({
                                                  label: appInterface.name,
                                                  value: appInterface._id,
                                              })) || []
                                        : []
                                }
                                onChange={handleFieldChange}
                                disabled={!logFilterData.appInstanceId.length}
                            />
                        </div>
                        <div className="filterLogContentField">
                            <Typography className="filterLogContentFieldLabel">Resource</Typography>
                            <SelectField
                                menuWidth={60}
                                name="appResourceId"
                                label={null}
                                value={logFilterData.appResourceId}
                                placeholder="Select Resource"
                                renderWithGrid={false}
                                required={false}
                                items={appData.resources.map((resource) => ({
                                    label: resource.name,
                                    value: resource._id,
                                }))}
                                onChange={handleFieldChange}
                                disabled={!logFilterData.appInterfaceId.length}
                            />
                        </div>
                        <div className="filterLogContentField">
                            <Typography className="filterLogContentFieldLabel">Action</Typography>
                            <SelectField
                                menuWidth={60}
                                name="appActionId"
                                label={null}
                                value={logFilterData.appActionId}
                                placeholder="Select Action"
                                renderWithGrid={false}
                                required={false}
                                items={appData.actions.map((action) => ({
                                    label: action.name,
                                    value: action._id,
                                }))}
                                onChange={handleFieldChange}
                                disabled={!logFilterData.appResourceId.length}
                            />
                        </div>
                    </>
                );
            case 'comparison':
            case 'mappings':
            case 'integrations':

            default:
                return null;
        }
    };

    const canApplyFilters = () => {
        if (logFilterData?.logName || logFilterData?.logStatus || logFilterData?.startDate) {
            return true;
        } else {
            switch (selectedLogType.name) {
                case 'api':
                    return logFilterData.route.length || logFilterData.ipAddress.length;
                case 'app-request':
                    return (
                        logFilterData.appInstanceId.length ||
                        logFilterData.appInterfaceId.length ||
                        logFilterData.appResourceId.length ||
                        logFilterData.appActionId.length
                    );
                case 'comparison':
                case 'mappings':
                case 'integrations':
                default:
                    break;
            }

            return false;
        }
    };

    return (
        <div className={`filterLogContent${isVisible ? ' filterLogContentVisible' : ''}`}>
            <div className="filterLogTitle">
                <Typography className="filterLogTitleInner">
                    Filter
                    <span className="filterLogTitleLine"> </span>
                </Typography>
                <PrimaryButton
                    color="#3A71D5 !important"
                    backgroundColor="#fff"
                    width="95px"
                    height="32px"
                    fontSize="13px"
                    margin="0px 0px 0px 15px"
                    padding="0px"
                    borderRadius="3px"
                    disabled={!canApplyFilters()}
                    className={canApplyFilters() ? undefined : 'canApplyFiltersDisabled'}
                    onClick={handleApplyFilters}
                >
                    Apply Filters
                </PrimaryButton>
            </div>

            <div className={`filterLogContentFields${isIntegrationLog ? '' : ' expandFilterLogContentFields'}`}>
                {isIntegrationLog && (
                    <div className="filterLogContentField">
                        <Typography className="filterLogContentFieldLabel">Name</Typography>
                        <TextField
                            name="logName"
                            label={null}
                            renderWithGrid={false}
                            placeholder={`Search Workflow Name`}
                            required={false}
                            inputProps={integrationWorkflowFieldInputProps}
                            sx={workflowNameInputStyle}
                            value={logFilterData.logName}
                            onChange={handleFieldChange}
                        />
                    </div>
                )}

                {renderAdditionalFilters()}

                <div className="filterLogContentField filterLogContentDateField">
                    <Typography className="filterLogContentFieldLabel">Start Time</Typography>
                    <div className="filterLogContentDateFieldWrapper">
                        <DatePicker
                            disableFuture={true}
                            value={logFilterData?.startDate}
                            onChange={handleDateChange('startDate')}
                            className="filterLogContentDateInput"
                            slotProps={datePickerSlotProps}
                        />
                        <DesktopTimePicker
                            ampm={false}
                            value={logFilterData?.startDate}
                            onChange={handleTimeChange('startDate')}
                            className="filterLogContentTimeInput"
                            views={timeViews}
                            disabled={!logFilterData?.startDate}
                        />
                    </div>
                </div>

                <div className="filterLogContentField filterLogContentDateField">
                    <Typography className="filterLogContentFieldLabel">End Time</Typography>
                    <div className="filterLogContentDateFieldWrapper">
                        <DatePicker
                            value={logFilterData?.endDate}
                            disableFuture={true}
                            onChange={handleDateChange('endDate')}
                            className="filterLogContentDateInput"
                            disabled={!logFilterData.startDate}
                        />
                        <DesktopTimePicker
                            ampm={false}
                            value={logFilterData?.endDate}
                            onChange={handleTimeChange('endDate')}
                            className="filterLogContentTimeInput"
                            views={timeViews}
                            disabled={!logFilterData.endDate}
                        />
                    </div>
                </div>

                <div className="filterLogContentField">
                    <Typography className="filterLogContentFieldLabel">Status</Typography>
                    <SelectField
                        menuWidth={60}
                        name="logStatus"
                        label={null}
                        value={logFilterData.logStatus}
                        placeholder="Select Status"
                        renderWithGrid={false}
                        required={false}
                        items={integrationStatuses}
                        onChange={handleFieldChange}
                    />
                </div>
            </div>
        </div>
    );
};

export default LogFilterContent;
