import { memo, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import styles from './styles';
import TableColumns from './TableColumns';
import { fetchLogs } from 'utils/loggingUtils';
import TableContainer from '../../Containers/TableContainer';
import ExpandedComponent from './ExpandedComponent';
import { useSelectedLog } from 'hooks/useOrganizationLoggingHook';

const OrganizationLoggingTable = () => {
    const navigate = useNavigate(),
        { loading, data = {} } = useSelectedLog(),
        results = data?.results || [],
        totalResults = data?.totalNumberOfResults || results?.length || 0,
        itemsPerPage = data?.resultSetSize || totalResults?.length || 10,
        currentPage = data?.currentResultSetNumber || 1;

    const [shouldResetToDefaultPage, setShouldResetToDefaultPage] = useState(false);

    /**
     * Handle items per page change
     */
    const handleItemsPerPageChange = async (e) => {
        setShouldResetToDefaultPage(true);

        fetchLogs({
            pageNo: 1,
            limit: +e.target.value,
        });
    };

    // Custom implementation to handle items per page
    useEffect(() => {
        const paginationElem = document.querySelector('.rdt_Pagination select');
        paginationElem?.addEventListener('change', handleItemsPerPageChange, false);

        return () => {
            paginationElem?.removeEventListener('change', handleItemsPerPageChange);
        };
    }, [results]);

    const handlePageChange = async (newPage) => {
        setShouldResetToDefaultPage(true);
        fetchLogs({ pageNo: newPage });
    };

    const expandedIcons = {
        expanded: <ExpandMoreIcon color="primary" className="toggleLogBtnSvg" />,
        collapsed: <ChevronRightIcon color="primary" className="toggleLogBtnSvg" />,
    };

    const itemsPerPageList = [10, 20, 30, 40, 50],
        containerProps = { unmountTable: true };

    const renderTableColumns = TableColumns({
        navigate,
    });

    return (
        <Box sx={styles.container}>
            <TableContainer
                containerProps={containerProps}
                paginationRowsPerPageOptions={itemsPerPageList}
                paginationResetDefaultPage={shouldResetToDefaultPage}
                expandableRowsComponent={ExpandedComponent}
                paginationDefaultPage={currentPage}
                paginationTotalRows={totalResults}
                paginationPerPage={itemsPerPage}
                expandableRows={false}
                expandableIcon={expandedIcons}
                paginationServer={true}
                onChangePage={handlePageChange}
                emptyDataText="No log found"
                loading={loading}
                columns={renderTableColumns}
                style={styles.tableContainer({ isLoading: false })}
                data={results}
            />
        </Box>
    );
};

export default memo(OrganizationLoggingTable);
