import { useState } from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import RefreshIcon from '@mui/icons-material/Refresh';

import { getLogsByType } from 'utils/loggingUtils';
import { getCurrentOrganizationId } from 'utils/loginUtils';

const RefreshLogButton = ({ logType }) => {
    const [isRefreshing, setIsRefreshing] = useState(false);

    const buttonStyle = {
        borderRadius: '3px',
        fontSize: '14px',
        margin: '0px',
        height: '32px',
        padding: '0px 10px',
        border: '1px solid #3A71D5',
        columnGap: '4px',
        marginRight: '10px',
    };

    const handleRefresh = () => {
        setIsRefreshing(true);
        getLogsByType({
            logType: logType.name,
            organizationId: getCurrentOrganizationId(),
        });
        setIsRefreshing(false);
    };

    return (
        <Button style={buttonStyle} disabled={isRefreshing} onClick={handleRefresh}>
            <RefreshIcon style={{ fontSize: '14px' }} />
            <Typography style={{ fontSize: '14px' }}>Refresh</Typography>
        </Button>
    );
};

RefreshLogButton.propTypes = {
    logType: PropTypes.object.isRequired,
};

export default RefreshLogButton;
