import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import JSONInput from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/en';

import styles from './styles';
import { setViewLogRequest, useViewLogRequest } from 'hooks/useOrganizationLoggingHook';
import CustomBootstrapDialog, { BootstrapModalContent, BootstrapModalTitle } from '../BootstrapModal';
import ResponsesStatus from 'components/ResponseStatus';
import RenderResponse from './RenderResponse';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import { showSnackBarErrorNotification, showSnackBarSuccessNotification } from 'utils/snackBarNotificationUtils';

const ViewLogRequestModal = () => {
    const { open, title, data, view } = useViewLogRequest(),
        isRequestView = view === 'request';
    let responseData = false,
        requestData = false;

    if (
        (data?.response && typeof data?.response === 'object' && Object.keys(data.response).length > 0) ||
        (data?.responseBody && typeof data?.responseBody === 'object' && Object.keys(data.responseBody).length > 0) ||
        (data?.resBody && typeof data?.resBody === 'object' && Object.keys(data.resBody).length > 0)
    ) {
        responseData = data?.response || data?.responseBody || data.resBody;
    }

    if (
        (data?.request && typeof data?.request === 'object' && Object.keys(data.request).length > 0) ||
        (data?.requestBody && typeof data?.requestBody === 'object' && Object.keys(data.requestBody).length > 0) ||
        (data?.reqBody && typeof data?.reqBody === 'object' && Object.keys(data.reqBody).length > 0)
    ) {
        requestData = data?.request || data?.requestBody || data.reqBody;
    }

    const handleCloseModal = () => {
        setViewLogRequest({ open: false, title: '', data: {}, view: '' });
    };

    /**
     * Copy response
     * @param {*} dataToCopy
     * @return {function}
     */
    const handleCopyResponse =
        (dataToCopy = null) =>
        () => {
            const clipboardText = JSON.stringify(dataToCopy || data);

            navigator.clipboard?.writeText(clipboardText)?.then(
                () => {
                    showSnackBarSuccessNotification('Copied successfully!');
                },
                () => {
                    showSnackBarErrorNotification('Unable to copy data');
                },
            );
        };

    const rootStyle = {
            backgroundColor: '#fff',
            padding: '10px 5px',
            minHeight: '90px',

            '& .logRequestContent': {
                display: 'flex',
                flexWrap: 'wrap',
                fontSize: '14px',
            },
        },
        dialogActionStyle = {
            borderTop: '1px solid #e1dfdfa8',
            padding: '6px 0px 0px 0px',
            backgroundColor: 'eeeeee1f',
            marginBottom: '-15px',
        },
        gridContainerStyleIntelyToken = {
            ...styles.gridContainer,
            margin: '25px auto 20px auto',
        };

    return (
        <CustomBootstrapDialog keepMounted open={open} scroll="paper" sx={styles.paperRoot}>
            <BootstrapModalTitle onClose={handleCloseModal}>{title}</BootstrapModalTitle>

            <BootstrapModalContent>
                <Box spacing={2} sx={rootStyle}>
                    <DialogContentText tabIndex={-1} component="div">
                        {open ? (
                            <div style={styles.gridContainerWrapper}>
                                {isRequestView ? (
                                    <>
                                        <div className="requestItem">
                                            <Typography component="div" style={styles.headingQueryParams}>
                                                Query Params
                                                <PrimaryButton
                                                    height="25px"
                                                    margin="0px 0px 0px 10px"
                                                    borderRadius="3px"
                                                    padding="0px 10px"
                                                    width="auto"
                                                    fontSize="13px"
                                                    onClick={handleCopyResponse(data?.params || {})}
                                                    sx={styles.copyResponseBtn}
                                                >
                                                    Copy
                                                </PrimaryButton>
                                            </Typography>

                                            <JSONInput
                                                placeholder={data?.params || {}}
                                                locale={locale}
                                                height="20vh"
                                                width="100%"
                                                onKeyPressUpdate={false}
                                                confirmGood={false}
                                                viewOnly={true}
                                                style={styles.jsonInput}
                                            />
                                        </div>

                                        {requestData ? (
                                            <div className="requestItem">
                                                <Typography component="div" style={styles.headingQueryParams}>
                                                    Request Body
                                                    <PrimaryButton
                                                        height="25px"
                                                        margin="0px 0px 0px 10px"
                                                        borderRadius="3px"
                                                        padding="0px 10px"
                                                        width="auto"
                                                        fontSize="13px"
                                                        onClick={handleCopyResponse(requestData)}
                                                        sx={styles.copyResponseBtn}
                                                    >
                                                        Copy
                                                    </PrimaryButton>
                                                </Typography>

                                                <JSONInput
                                                    placeholder={requestData || {}}
                                                    locale={locale}
                                                    height="20vh"
                                                    width="100%"
                                                    onKeyPressUpdate={false}
                                                    confirmGood={false}
                                                    viewOnly={true}
                                                    style={styles.jsonInput}
                                                />
                                            </div>
                                        ) : null}

                                        {data?.restfulCall &&
                                        typeof data?.restfulCall === 'object' &&
                                        Object.keys(data.restfulCall).length > 0 ? (
                                            <div className="requestItem">
                                                <Typography component="div" style={styles.headingQueryParams}>
                                                    Restful Call
                                                    <PrimaryButton
                                                        height="25px"
                                                        margin="0px 0px 0px 10px"
                                                        borderRadius="3px"
                                                        padding="0px 10px"
                                                        width="auto"
                                                        fontSize="13px"
                                                        onClick={handleCopyResponse(data.restfulCall)}
                                                        sx={styles.copyResponseBtn}
                                                    >
                                                        Copy
                                                    </PrimaryButton>
                                                </Typography>

                                                <JSONInput
                                                    placeholder={data.restfulCall || {}}
                                                    locale={locale}
                                                    height="40vh"
                                                    width="100%"
                                                    onKeyPressUpdate={false}
                                                    confirmGood={false}
                                                    viewOnly={true}
                                                    style={styles.jsonInput}
                                                />
                                            </div>
                                        ) : null}

                                        {data?.tokenCall &&
                                        typeof data?.tokenCall === 'object' &&
                                        Object.keys(data.tokenCall).length > 0 ? (
                                            <div className="requestItem">
                                                <Typography component="div" style={styles.headingQueryParams}>
                                                    Token Call{' '}
                                                    <PrimaryButton
                                                        height="25px"
                                                        margin="0px 0px 0px 10px"
                                                        borderRadius="3px"
                                                        padding="0px 10px"
                                                        width="auto"
                                                        fontSize="13px"
                                                        onClick={handleCopyResponse(data.tokenCall)}
                                                        sx={styles.copyResponseBtn}
                                                    >
                                                        Copy
                                                    </PrimaryButton>
                                                </Typography>

                                                <JSONInput
                                                    placeholder={data.tokenCall || {}}
                                                    locale={locale}
                                                    height="40vh"
                                                    width="100%"
                                                    onKeyPressUpdate={false}
                                                    confirmGood={false}
                                                    viewOnly={true}
                                                    style={styles.jsonInput}
                                                />
                                            </div>
                                        ) : null}
                                    </>
                                ) : (
                                    <>
                                        <Typography component="div" sx={styles.statusCode}>
                                            Status:{' '}
                                            <ResponsesStatus showCode={true} statusCode={data?.statusCode || 0} />
                                        </Typography>

                                        {data?.errorObject &&
                                        typeof data?.errorObject === 'object' &&
                                        Object.keys(data.errorObject).length > 0 ? (
                                            <div className="requestItem requestItemResponse">
                                                <Typography component="div" style={styles.headingQueryParams}>
                                                    Error{' '}
                                                    <PrimaryButton
                                                        height="25px"
                                                        margin="0px 0px 0px 10px"
                                                        borderRadius="3px"
                                                        padding="0px 10px"
                                                        width="auto"
                                                        fontSize="13px"
                                                        onClick={handleCopyResponse(data.errorObject)}
                                                        sx={styles.copyResponseBtn}
                                                    >
                                                        Copy
                                                    </PrimaryButton>
                                                </Typography>

                                                <JSONInput
                                                    placeholder={data.errorObject || {}}
                                                    locale={locale}
                                                    height="40vh"
                                                    width="100%"
                                                    onKeyPressUpdate={false}
                                                    confirmGood={false}
                                                    viewOnly={true}
                                                    style={styles.jsonInput}
                                                />
                                            </div>
                                        ) : null}

                                        <Grid container spacing={2} style={styles.gridContainer}>
                                            <RenderResponse view={view} data={data} />
                                        </Grid>

                                        {data?.intelyToken ? (
                                            <Grid container spacing={2} style={gridContainerStyleIntelyToken}>
                                                <Grid item xs={12} md={12}>
                                                    <Typography style={styles.heading}>Intely Token</Typography>
                                                    <Typography style={styles.intelyToken}>
                                                        {data.intelyToken}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        ) : null}

                                        {responseData ? (
                                            <div className="requestItem requestItemResponse">
                                                <Typography component="div" style={styles.headingQueryParams}>
                                                    Response{' '}
                                                    <PrimaryButton
                                                        height="25px"
                                                        margin="0px 0px 0px 10px"
                                                        borderRadius="3px"
                                                        padding="0px 10px"
                                                        width="auto"
                                                        fontSize="13px"
                                                        onClick={handleCopyResponse(responseData)}
                                                        sx={styles.copyResponseBtn}
                                                    >
                                                        Copy
                                                    </PrimaryButton>
                                                </Typography>

                                                <JSONInput
                                                    placeholder={responseData}
                                                    locale={locale}
                                                    height="40vh"
                                                    width="100%"
                                                    onKeyPressUpdate={false}
                                                    confirmGood={false}
                                                    viewOnly={true}
                                                    style={styles.jsonInput}
                                                />
                                            </div>
                                        ) : null}
                                        {data?.responseBody && typeof data.responseBody === 'object' && (
                                            <>
                                                {data.responseBody?.logs &&
                                                    typeof data.responseBody?.logs === 'object' && (
                                                        <Grid container spacing={2} style={styles.gridContainer}>
                                                            <Grid item xs={12} md={12}>
                                                                <Typography style={styles.heading}>
                                                                    Process Log
                                                                </Typography>
                                                                <div style={styles.processLog}>
                                                                    <ul style={{ padding: 0, margin: 0 }}>
                                                                        {data.responseBody.logs.map((logLine, key) => {
                                                                            return (
                                                                                <li
                                                                                    key={key}
                                                                                    style={{ padding: '2px 0' }}
                                                                                >
                                                                                    {`${key + 1}. ${logLine}`}
                                                                                </li>
                                                                            );
                                                                        })}
                                                                    </ul>
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    )}
                                            </>
                                        )}
                                    </>
                                )}
                            </div>
                        ) : null}
                    </DialogContentText>

                    <DialogActions style={dialogActionStyle}>
                        {!isRequestView && (
                            <PrimaryButton
                                height="35px"
                                margin="15px 15px 0px 0px"
                                borderRadius="3px"
                                padding="0px 10px"
                                onClick={handleCopyResponse()}
                                sx={styles.copyResponseBtn}
                            >
                                Copy Response
                            </PrimaryButton>
                        )}

                        <PrimaryButton
                            height="35px"
                            color="primary"
                            backgroundColor="#fff"
                            margin="15px 0px 0px 0px"
                            padding="0px 10px"
                            onClick={handleCloseModal}
                            sx={styles.closeModalBtn}
                            borderRadius="3px"
                        >
                            Close
                        </PrimaryButton>
                    </DialogActions>
                </Box>
            </BootstrapModalContent>
        </CustomBootstrapDialog>
    );
};

export default ViewLogRequestModal;
