import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect, memo } from 'react';

import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import Grid from '@mui/material/Grid';
import FormHelperText from '@mui/material/FormHelperText';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { InputLabel, Divider } from '@mui/material';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import BusinessIcon from '@mui/icons-material/Business';

import BootstrapTooltip from 'components/Tooltips/BootstrapTooltip';
import useSelectedOrganization from 'hooks/useSelectedOrganizationHook';
import { getOrganizationData, updateOrganizationData } from 'utils/organizationUtils';
import { showSnackBarErrorNotification, showSnackBarSuccessNotification } from 'utils/snackBarNotificationUtils';

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: '#44b700',
        color: '#44b700',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: 'ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
        },
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
        },
    },
}));

const OrganizationEdit = () => {
    const [profileimg] = useState();
    const [, setLoading] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [organization, setOrganization] = useState({
        _id: '',
        name: '',
        applications: [],
        addressLines: [],
        city: '',
        state: '',
        country: '',
        zipCode: '',
        aboutDescription: '',
        links: [],
        vendorList: [],
    });

    const [orgCopy, setOrgCopy] = useState({
        _id: '',
        name: '',
        applications: [],
        addressLines: [],
        city: '',
        state: '',
        country: '',
        zipCode: '',
        aboutDescription: '',
        links: [],
        vendorList: [],
    });

    const [currentSelectedOrganization, setCurrentSelectedOrganization] = useSelectedOrganization();
    const { organizationId } = currentSelectedOrganization;

    const navigate = useNavigate(),
        showUploadProfileImageButton = false;

    const setOrganizationData = (data) => {
        const organizationData = _.cloneDeep(data);
        const organizationDataCopy = _.cloneDeep(data);
        setOrganization(() => {
            return organizationData;
        });

        setOrgCopy(() => {
            return organizationDataCopy;
        });
    };

    const getOrganizationById = async () => {
        if (organizationId) {
            setLoading(true);
            await getOrganizationData(organizationId)
                .then(async (data) => {
                    if (data) {
                        setOrganizationData(data);
                        setLoading(false);
                    } else {
                        setOrganization(null);
                        setLoading(false);
                    }
                })
                .catch((e) => {
                    navigate('/dashboard');
                    setLoading(false);
                    showSnackBarErrorNotification(e.response.data.message);
                });
        }
    };

    const updateOrganizationById = async () => {
        setIsSubmitted(true);
        if (organizationId && validateOrganization()) {
            setLoading(true);
            await updateOrganizationData(organizationId, orgCopy)
                .then(async (data) => {
                    setLoading(false);
                    setIsSubmitted(false);
                    if (data) {
                        setOrganizationData(data);
                        if (currentSelectedOrganization.organizationId === data._id) {
                            const selectedOrganization = currentSelectedOrganization;
                            selectedOrganization['organizationName'] = data.name;
                            const dataTemp = { ...currentSelectedOrganization, ...selectedOrganization };
                            setCurrentSelectedOrganization(dataTemp);
                        }

                        showSnackBarSuccessNotification('Organization updated Successfully');
                    } else {
                        setOrganization(null);
                    }
                })
                .catch((e) => {
                    setIsSubmitted(false);
                    setLoading(false);
                    showSnackBarErrorNotification(e.message);
                });
        }
    };

    const getLinkKey = (key) => {
        if (orgCopy && orgCopy.links.length > 0) {
            const matchLink = orgCopy.links.find((link) => link.name === key);
            if (matchLink) {
                return matchLink.url;
            }
            return '';
        }
    };

    const getWebsiteLabel = (key) => {
        if (organization && organization.links.length > 0) {
            const matchLink = organization.links.find((link) => link.name === key);
            if (matchLink) {
                return matchLink.url;
            }
            return '';
        }
    };

    const getLinkIndex = (linkName) => {
        return orgCopy['links'].findIndex((link) => link.name === linkName);
    };

    const setInputFieldValues = (keyName, value, type) => {
        if (type === 'link') {
            const organizationTemp = orgCopy;
            if (organizationTemp['links'][getLinkIndex(keyName)]) {
                organizationTemp['links'][getLinkIndex(keyName)]['url'] = value;
            } else {
                organizationTemp['links'].push({ name: keyName, url: value });
            }
            const organizationObj = { ...orgCopy, ...organizationTemp };
            setOrgCopy(organizationObj);
        } else {
            const organizationTemp = orgCopy;
            organizationTemp[keyName] = value;
            const organizationObj = { ...orgCopy, ...organizationTemp };
            setOrgCopy(organizationObj);
        }
    };

    const validateOrganization = () => {
        return (
            orgCopy.name &&
            orgCopy.addressLines?.[0] &&
            orgCopy.state &&
            orgCopy.country &&
            orgCopy.city &&
            orgCopy.zipCode &&
            orgCopy.billingContact &&
            orgCopy.billingEmail &&
            getLinkKey('Website')
        );
    };

    useEffect(() => {
        getOrganizationById();
    }, [currentSelectedOrganization]);

    const userIconStyle = {
        width: 120,
        height: 120,
        color: '#C4C4C4',
        cursor: 'pointer',
        boxShadow: '0 0 98px 6px rgba(0, 0, 0, 0.2)',
        borderRadius: '50%',
    };

    return (
        <Container component="main" maxWidth="md">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 5,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'start',
                    paddingBottom: 5,
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                >
                    <Grid container spacing={2}>
                        <Grid
                            item
                            xs={8}
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                }}
                            >
                                <StyledBadge
                                    overlap="circular"
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                >
                                    {profileimg ? (
                                        <img src={profileimg} alt={organization?.name || ''} style={userIconStyle} />
                                    ) : (
                                        <Avatar
                                            sx={{
                                                width: '120px',
                                                height: '120px',
                                                fontSize: '15px',
                                                color: '#fff',
                                                border: '1px solid #6198FF',
                                                borderRadius: '50%',
                                                background: '#E0E0E0',
                                            }}
                                        >
                                            <BusinessIcon
                                                sx={{
                                                    width: '60px',
                                                    height: '60px',
                                                }}
                                            />
                                        </Avatar>
                                    )}
                                </StyledBadge>

                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        alignSelf: 'end',
                                        marginBottom: '5px',
                                        padding: 0,
                                    }}
                                >
                                    {showUploadProfileImageButton && (
                                        <>
                                            <BootstrapTooltip title="Upload Profile" placement="bottom">
                                                <IconButton
                                                    variant="contained"
                                                    component="label"
                                                    color="primary"
                                                    sx={{ padding: 0 }}
                                                >
                                                    <AddAPhotoIcon style={{ width: 20, height: 20 }} />
                                                    <input type="file" hidden name="myImage" accept="image/*" />
                                                </IconButton>
                                            </BootstrapTooltip>
                                            {profileimg && (
                                                <BootstrapTooltip title="Remove Profile" placement="bottom">
                                                    <IconButton variant="contained" component="label" color="primary">
                                                        <RemoveCircleOutlineIcon style={{ width: 20, height: 20 }} />
                                                    </IconButton>
                                                </BootstrapTooltip>
                                            )}
                                        </>
                                    )}
                                </Box>
                            </Box>

                            <Box
                                sx={{
                                    display: 'inline',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    paddingLeft: 3,
                                }}
                            >
                                <Typography component="h1" variant="h4">
                                    {organization?.name || ''}
                                </Typography>
                                <Typography>{getWebsiteLabel('Website') || ''}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={4} textAlign="end" justifyContent="center" alignItems="end">
                            <LoadingButton
                                type="submit"
                                variant="contained"
                                sx={{
                                    mt: 5,
                                    height: '40px',
                                    '&.MuiLoadingButton-root:hover': {
                                        backgroundColor: '#333F99',
                                        color: '#FFFFFF',
                                        boxShadow: 'none',
                                    },
                                }}
                                className="secondary"
                                onClick={() => {
                                    updateOrganizationById();
                                }}
                            >
                                {'Save'}
                            </LoadingButton>
                        </Grid>

                        <Grid item xs={12} mt={1} sm={6}>
                            <InputLabel>
                                {' '}
                                Name{' '}
                                <Typography sx={{ color: 'red' }} variant="subtitle1" component="span">
                                    {' '}
                                    *
                                </Typography>{' '}
                            </InputLabel>
                            <TextField
                                required
                                variant="outlined"
                                sx={{ mt: 1 }}
                                fullWidth
                                name="name"
                                autoComplete="off"
                                placeholder="Name"
                                value={orgCopy.name ? orgCopy.name : ''}
                                onChange={(e) => setInputFieldValues('name', e.target.value)}
                                error={isSubmitted && !orgCopy.name}
                            />
                            {isSubmitted && !orgCopy.name && (
                                <FormHelperText error={isSubmitted && !orgCopy.name}>
                                    {'Name is required'}
                                </FormHelperText>
                            )}
                        </Grid>
                        <Grid item xs={12} mt={1} sm={6}>
                            <InputLabel>
                                {' '}
                                Website{' '}
                                <Typography sx={{ color: 'red' }} variant="subtitle1" component="span">
                                    {' '}
                                    *
                                </Typography>{' '}
                            </InputLabel>
                            <TextField
                                required
                                variant="outlined"
                                sx={{ mt: 1 }}
                                fullWidth
                                name="organizationWebsite"
                                autoComplete="off"
                                placeholder="Website"
                                value={getLinkKey('Website') || ''}
                                onChange={(e) => setInputFieldValues('Website', e.target.value, 'link')}
                                error={isSubmitted && !getLinkKey('Website')}
                            />
                            {isSubmitted && !getLinkKey('Website') && (
                                <FormHelperText error={isSubmitted && !getLinkKey('Website')}>
                                    {'Website is required'}
                                </FormHelperText>
                            )}
                        </Grid>

                        <Divider
                            sx={{
                                background: '#BDBDBD',
                                mt: 5,
                                mb: 1,
                                width: '100%',
                                height: 2,
                                marginLeft: '5px',
                            }}
                        />

                        <Grid item xs={12} mt={1} sm={6}>
                            <InputLabel>
                                {' '}
                                Address Line 1{' '}
                                <Typography sx={{ color: 'red' }} variant="subtitle1" component="span">
                                    {' '}
                                    *
                                </Typography>{' '}
                            </InputLabel>
                            <TextField
                                required
                                variant="outlined"
                                sx={{ mt: 1 }}
                                fullWidth
                                name="addressline1"
                                autoComplete="off"
                                placeholder="Address Line 1"
                                value={orgCopy.addressLines[0] ? orgCopy.addressLines[0] : ''}
                                onChange={(e) => {
                                    const organizationTemp = orgCopy;
                                    organizationTemp['addressLines'][0] = e.target.value;
                                    const organizationObj = { ...orgCopy, ...organizationTemp };
                                    setOrgCopy(organizationObj);
                                }}
                                error={isSubmitted && !orgCopy.addressLines[0]}
                            />
                            {isSubmitted && !orgCopy.addressLines[0] && (
                                <FormHelperText error={isSubmitted && !orgCopy.addressLines[0]}>
                                    {'Address Line 1 is required'}
                                </FormHelperText>
                            )}
                        </Grid>
                        <Grid item xs={12} mt={1} sm={6}>
                            <InputLabel> Address Line 2 </InputLabel>
                            <TextField
                                variant="outlined"
                                sx={{ mt: 1 }}
                                fullWidth
                                name="addressline2"
                                autoComplete="off"
                                placeholder="Address Line 2"
                                value={orgCopy.addressLines[1] ? orgCopy.addressLines[1] : ''}
                                onChange={(e) => {
                                    const organizationTemp = orgCopy;
                                    organizationTemp['addressLines'][1] = e.target.value;
                                    const organizationObj = { ...orgCopy, ...organizationTemp };
                                    setOrgCopy(organizationObj);
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} mt={1} sm={6}>
                            <InputLabel>
                                {' '}
                                City{' '}
                                <Typography sx={{ color: 'red' }} variant="subtitle1" component="span">
                                    {' '}
                                    *
                                </Typography>{' '}
                            </InputLabel>
                            <TextField
                                required
                                variant="outlined"
                                sx={{ mt: 1 }}
                                fullWidth
                                name="city"
                                autoComplete="off"
                                placeholder="City"
                                value={orgCopy.city ? orgCopy.city : ''}
                                onChange={(e) => {
                                    setInputFieldValues('city', e.target.value);
                                }}
                                error={isSubmitted && !orgCopy.city}
                            />
                            {isSubmitted && !orgCopy.city && (
                                <FormHelperText error={isSubmitted && !orgCopy.city}>
                                    {'City is required'}
                                </FormHelperText>
                            )}
                        </Grid>

                        <Grid item xs={12} mt={1} sm={6}>
                            <InputLabel>
                                {' '}
                                State{' '}
                                <Typography sx={{ color: 'red' }} variant="subtitle1" component="span">
                                    {' '}
                                    *
                                </Typography>{' '}
                            </InputLabel>
                            <TextField
                                required
                                variant="outlined"
                                sx={{ mt: 1 }}
                                fullWidth
                                name="state"
                                autoComplete="off"
                                placeholder="State"
                                value={orgCopy.state ? orgCopy.state : ''}
                                onChange={(e) => {
                                    setInputFieldValues('state', e.target.value);
                                }}
                                error={isSubmitted && !orgCopy.state}
                            />
                            {isSubmitted && !orgCopy.state && (
                                <FormHelperText error={isSubmitted && !orgCopy.state}>
                                    {'State is required'}
                                </FormHelperText>
                            )}
                        </Grid>

                        <Grid item xs={12} mt={1} sm={6}>
                            <InputLabel>
                                {' '}
                                Country{' '}
                                <Typography sx={{ color: 'red' }} variant="subtitle1" component="span">
                                    {' '}
                                    *
                                </Typography>{' '}
                            </InputLabel>
                            <TextField
                                required
                                variant="outlined"
                                sx={{ mt: 1 }}
                                fullWidth
                                name="country"
                                autoComplete="off"
                                placeholder="Country"
                                value={orgCopy.country ? orgCopy.country : ''}
                                onChange={(e) => {
                                    setInputFieldValues('country', e.target.value);
                                }}
                                error={isSubmitted && !orgCopy.country}
                            />
                            {isSubmitted && !orgCopy.country && (
                                <FormHelperText error={isSubmitted && !orgCopy.country}>
                                    {'Country is required'}
                                </FormHelperText>
                            )}
                        </Grid>

                        <Grid item xs={12} mt={1} sm={6}>
                            <InputLabel>
                                {' '}
                                Zip Code{' '}
                                <Typography sx={{ color: 'red' }} variant="subtitle1" component="span">
                                    {' '}
                                    *
                                </Typography>{' '}
                            </InputLabel>
                            <TextField
                                required
                                variant="outlined"
                                sx={{ mt: 1 }}
                                fullWidth
                                name="zipcode"
                                autoComplete="off"
                                placeholder="Zip Code"
                                value={orgCopy.zipCode ? orgCopy.zipCode : ''}
                                onChange={(e) => {
                                    setInputFieldValues('zipCode', e.target.value);
                                }}
                                error={isSubmitted && !orgCopy.zipCode}
                            />
                            {isSubmitted && !orgCopy.zipCode && (
                                <FormHelperText error={isSubmitted && !orgCopy.zipCode}>
                                    {'Zipcode is required'}
                                </FormHelperText>
                            )}
                        </Grid>

                        <Grid item xs={12} mt={1} sm={6}>
                            <InputLabel sx={{ mb: 1 }}>About</InputLabel>
                            <TextField
                                variant="outlined"
                                sx={{
                                    m: 0,
                                    p: 0,
                                    '&::placeholder': {
                                        padding: '0px !important',
                                    },
                                    '&.MuiOutlinedInput-root': {
                                        paddingLeft: '6px',
                                        paddingTop: 0,
                                    },
                                    '& .MuiInputBase-root': {
                                        paddingTop: '0px !important',
                                        paddingBottom: '0px !important',
                                        backgroundColor: '#F9F9F9',
                                    },
                                }}
                                fullWidth
                                name="aboutCompany"
                                autoComplete="off"
                                placeholder="About"
                                multiline
                                rows={4}
                                value={orgCopy.aboutDescription ? orgCopy.aboutDescription : ''}
                                onChange={(e) => {
                                    setInputFieldValues('aboutDescription', e.target.value);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} mt={1} sm={6} />

                        <Grid item xs={12} mt={1} sm={6}>
                            <InputLabel>
                                {' '}
                                Billing Contact{' '}
                                <Typography sx={{ color: 'red' }} variant="subtitle1" component="span">
                                    {' '}
                                    *
                                </Typography>{' '}
                            </InputLabel>
                            <TextField
                                required
                                variant="outlined"
                                sx={{ mt: 1 }}
                                fullWidth
                                name="billingContact"
                                autoComplete="off"
                                placeholder="Billing Contact"
                                value={orgCopy.billingContact ? orgCopy.billingContact : ''}
                                onChange={(e) => {
                                    setInputFieldValues('billingContact', e.target.value);
                                }}
                                error={isSubmitted && !orgCopy.billingContact}
                            />
                            {isSubmitted && !orgCopy.billingContact && (
                                <FormHelperText error={isSubmitted && !orgCopy.billingContact}>
                                    {'Billing Contact is required'}
                                </FormHelperText>
                            )}
                        </Grid>

                        <Grid item xs={12} mt={1} sm={6}>
                            <InputLabel>
                                {' '}
                                Billing Email{' '}
                                <Typography sx={{ color: 'red' }} variant="subtitle1" component="span">
                                    {' '}
                                    *
                                </Typography>{' '}
                            </InputLabel>
                            <TextField
                                required
                                variant="outlined"
                                sx={{ mt: 1 }}
                                fullWidth
                                name="billingEmail"
                                autoComplete="off"
                                placeholder="Billing Email"
                                value={orgCopy.billingEmail ? orgCopy.billingEmail : ''}
                                onChange={(e) => {
                                    setInputFieldValues('billingEmail', e.target.value.toLowerCase());
                                }}
                                error={isSubmitted && !orgCopy.billingEmail}
                            />
                            {isSubmitted && !orgCopy.billingEmail && (
                                <FormHelperText error={isSubmitted && !orgCopy.billingEmail}>
                                    {'Billing Email is required'}
                                </FormHelperText>
                            )}
                        </Grid>

                        <Grid item xs={12} mt={1} sm={12}>
                            <Divider
                                sx={{
                                    background: '#BDBDBD',
                                    mt: 2,
                                    mb: 3.3,
                                    width: '100%',
                                    height: 2,
                                }}
                            />
                            <Typography mb={2} mt={2}>
                                {'Social Media URLs'}
                            </Typography>
                        </Grid>

                        <Grid item xs={12} mt={1} sm={6}>
                            <InputLabel> LinkedIn </InputLabel>
                            <TextField
                                variant="outlined"
                                sx={{ mt: 1 }}
                                fullWidth
                                name="linkedin"
                                autoComplete="off"
                                placeholder="LinkedIn"
                                value={getLinkKey('LinkedIn') || ''}
                                onChange={(e) => setInputFieldValues('LinkedIn', e.target.value, 'link')}
                            />
                        </Grid>

                        <Grid item xs={12} mt={1} sm={6}>
                            <InputLabel>Twitter </InputLabel>
                            <TextField
                                variant="outlined"
                                sx={{ mt: 1 }}
                                fullWidth
                                name="twitter"
                                autoComplete="off"
                                placeholder="Twitter"
                                value={getLinkKey('Twitter') || ''}
                                onChange={(e) => setInputFieldValues('Twitter', e.target.value, 'link')}
                            />
                        </Grid>
                        <Grid item xs={12} mt={1} sm={6}>
                            <InputLabel> Instagram </InputLabel>
                            <TextField
                                variant="outlined"
                                sx={{ mt: 1 }}
                                fullWidth
                                name="instagram"
                                autoComplete="off"
                                placeholder="Instagram"
                                value={getLinkKey('Instagram') || ''}
                                onChange={(e) => setInputFieldValues('Instagram', e.target.value, 'link')}
                            />
                        </Grid>

                        <Grid item xs={12} mt={1} sm={6}>
                            <InputLabel>Youtube </InputLabel>
                            <TextField
                                variant="outlined"
                                sx={{ mt: 1 }}
                                fullWidth
                                name="youtube"
                                autoComplete="off"
                                placeholder="Youtube"
                                value={getLinkKey('YouTube') || ''}
                                onChange={(e) => setInputFieldValues('YouTube', e.target.value, 'link')}
                            />
                        </Grid>
                        <Grid item xs={12} mt={1} sm={6}>
                            <InputLabel> Facebook </InputLabel>
                            <TextField
                                variant="outlined"
                                sx={{ mt: 1 }}
                                fullWidth
                                name="facebook"
                                autoComplete="off"
                                placeholder="Facebook"
                                value={getLinkKey('Facebook') || ''}
                                onChange={(e) => setInputFieldValues('Facebook', e.target.value, 'link')}
                            />
                        </Grid>
                        <Grid item xs={12} mt={1} sm={6}>
                            <InputLabel> Pinterest </InputLabel>
                            <TextField
                                variant="outlined"
                                sx={{ mt: 1 }}
                                fullWidth
                                name="pinterest"
                                autoComplete="off"
                                placeholder="Pinterest"
                                value={getLinkKey('Pinterest') || ''}
                                onChange={(e) => {
                                    setInputFieldValues('Pinterest', e.target.value, 'link');
                                }}
                            />
                        </Grid>
                        <Grid xs={12} mt={1} item sm={12} textAlign="end" mb={6}>
                            <LoadingButton
                                type="button"
                                variant="contained"
                                sx={{
                                    mt: 2,
                                    mr: 2,
                                    height: '40px',
                                    '&.secondary': {
                                        backgroundColor: '#E0E0E0',
                                        color: '#333333',
                                        boxShadow: 'none',
                                    },
                                    '&.secondary:hover': {
                                        backgroundColor: '#828282',
                                        color: '#333333',
                                        boxShadow: 'none',
                                    },
                                }}
                                className="secondary"
                                onClick={() => setOrganizationData(organization)}
                            >
                                {'Cancel'}
                            </LoadingButton>
                            <LoadingButton
                                type="button"
                                variant="contained"
                                sx={{
                                    mt: 2,
                                    height: '40px',
                                    '&.secondary:hover': {
                                        backgroundColor: '#333F99',
                                        color: '#FFFFFF',
                                    },
                                }}
                                className="secondary"
                                onClick={() => updateOrganizationById()}
                            >
                                {'Save'}
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Container>
    );
};

export default memo(OrganizationEdit);
