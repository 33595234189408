import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import CloseIcon from '@mui/icons-material/Close';

import { resetLogFilterData } from 'hooks/useOrganizationLoggingHook';
import { fetchLogs } from 'utils/loggingUtils';

const ClearFiltersButon = () => {
    const buttonStyle = {
        borderRadius: '3px',
        fontSize: '14px',
        margin: '0px',
        height: '32px',
        padding: '0px 10px',
        border: '1px solid #d53a3a',
        backgroundColor: '#d53a3a',
        columnGap: '4px',
        marginRight: '10px',
    };

    const handleClearFilters = () => {
        resetLogFilterData();
        fetchLogs();
    };

    return (
        <Button style={buttonStyle} onClick={handleClearFilters}>
            <Typography style={{ fontSize: '14px', color: 'white' }}>Clear Filters</Typography>
            <CloseIcon style={{ color: '#fff', fontSize: '14px' }} />
        </Button>
    );
};

export default ClearFiltersButon;
